import { Card, Form, Modal } from "antd";
import React from "react";
import { Col, Row } from "reactstrap";
import InputBox from "../../../../UtilityComponents/Inputs/InputBox";
import { CheckOutlined } from "@ant-design/icons";
import ChangePasswordForm from "../../../../Pages/Login/ChangePassword/ChangePasswordForm";
import "../../../../Styles/ChangePassword/stayle.css";
const ChangePasswordModal = (props) => {
  let { isModalVisible, setIsModalVisible } = props;

  const handleOnChangePass = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      open={isModalVisible}
      onOk={handleOnChangePass}
      okText="Confirm"
      footer={null}
      onCancel={() => {
        setIsModalVisible(false);
      }}
    >
      <ChangePasswordForm />
    </Modal>
  );
};

export default ChangePasswordModal;
